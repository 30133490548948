<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="exam">
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="container rounded-6 mb-5 py-3 px-3 px-md-5" v-if="exam.closed">
          <div class="row">
            <div class="col-12 d-flex justify-content-center my-2 ">
              <h1 class="text-max">نتیجه آزمون {{exam.examTitle}}</h1>
            </div>

            <div class="col-12 pb-1">
              <div class="row">
                <div class="col-12 col-md-6 mt-2">
                  <span class="text-muted">نام و نام خانوادگی :</span>
                  <span class="ml-1">{{ exam.userFullName }}</span>
                </div>
                <div class="col-12 col-md-6 mt-2">
                  <span class="text-muted">تاریخ برگزاری آزمون :</span>
                  <span class="ml-1">
                 {{ exam.closeTime.slice(11, 16) }}
                   -
                 {{ createJalaliDate(exam.closeTime.slice(0, 10)) }}
              </span>
                </div>
              </div>
            </div>

            <div class="col-12 pb-1">
              <div class="row">
                <div class="col-12 col-md-6 mt-2">
                  <span class="text-muted">تعداد کل پرسش‌ها :</span>
                  <span class="ml-1">{{ exam.totalQuestionCount }}</span>
                </div>
                <div class="col-12 col-md-6 mt-2">
                  <span class="text-muted">نمره شما :</span>
                  <div class="ml-1 d-inline-block">
                    <span> {{ exam.totalQuestionCount }} /</span>
                    <span class="text-success">{{ exam.score }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 pb-1">
              <div class="row">
                <div class="col-12 col-md-6 mt-2">
                  <span class="text-muted">تعداد و درصد پاسخ‌های درست :</span>
                  <span class="ml-1 text-success">{{ exam.score }} ({{  `${Math.ceil((exam.score/exam.totalQuestionCount)*100)} %`  }})</span>
                </div>
                <div class="col-12 col-md-6 mt-2">
                  <span class="text-muted">تعداد و درصد پاسخ‌های غلط :</span>
                  <span class="ml-1 text-danger">{{ exam.totalQuestionCount - exam.score }}  ({{  `${Math.ceil(( (exam.totalQuestionCount - exam.score) / exam.totalQuestionCount)*100)} %`  }})</span>
                </div>
              </div>
            </div>

            <div class="col-12 d-flex justify-content-start my-2">
              <strong class="text-max text-xl">پاسخ نامه</strong>
            </div>

            <div v-for="(question,idx) in exam.questions" :key="question.questionId" class="col-12 d-flex justify-content-start my-1">
              <div class="card text-dark bg-light mb-3 w-100">
                <div class="card-header">{{ question.question }}</div>
                <div class="card-body">
                  <div v-for="(answer,index) in question.answers" :key="answer.id" class="form-check">
                    <input disabled class="form-check-input" v-model="question.choosedAnswer.id" :value="answer.id" :name="answer.id" type="radio" :id="answer.id">
                    <label v-if="answer.id === question.choosedAnswer.id" class="form-check-label" :class="[question.choosedAnswer.isCorrect ? 'text-success' : 'text-danger']" :for="answer.id">{{ answer.answer }}</label>
                    <label v-else class="form-check-label" :class="[answer.isCorrect ? 'text-success' : '']" :for="answer.id">{{ answer.answer }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import { UserExamGetUserExamByExamIdRequest } from "@/libs/Api/UserExam";

export default {
  name: "MemberExamِثفشهم",
  title: "جزییات آزمون کاربر‌ - پنل ادمین مکس",
  data() {
    return {
      exam: null,
      showOverlay: false,
      examId:this.$route.params.id,
      baseUrl: Helper.baseUrl,
    }
  },
  async created() {
    await this.getExam()
  },
  methods: {
    async getExam(){
      let _this = this;
      _this.showOverlay = true;

      let data={
        id:_this.examId
      }
      let userExamGetUserExamByExamIdRequest = new UserExamGetUserExamByExamIdRequest(_this);
      userExamGetUserExamByExamIdRequest.setParams(data);
      await userExamGetUserExamByExamIdRequest.fetch(function (content){
        _this.showOverlay = false;
        if (content.isSuccess){
          _this.exam = content.data;
        }else {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              variant: 'danger',
              text: `آزمون مورد نظر یافت نشد.`,
            },
          })
        }
      },function (error){
        _this.showOverlay = false;
        console.log(error)
      })
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getAllMemberExams(nv, this.count);
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>